.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .popup-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 90%;
    width: 400px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    position: relative;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 16px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  
  h2 {
    margin-top: 0;
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  label {
    margin-bottom: 10px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    font-size: 14px;
  }
  
  input[type="email"],
  input[type="time"],
  input[type="text"],
  select {
    padding: 8px;
    font-size: 14px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
  }
  
  button[type="submit"] {
    background-color: #28a745;
    color: white;
    padding: 10px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  button[type="submit"]:hover {
    background-color: #218838;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .popup-content {
      width: 90%;
      padding: 15px;
    }
  
    input[type="email"],
    input[type="time"],
    input[type="text"],
    select {
      font-size: 13px;
      padding: 7px;
    }
  
    label {
      font-size: 13px;
    }
    
    button[type="submit"] {
      font-size: 15px;
      padding: 9px;
    }
  }
  
  @media (max-width: 480px) {
    .popup-content {
      width: 95%;
      padding: 10px;
    }
  
    .close-button {
      top: 5px;
      right: 5px;
    }
  
    input[type="email"],
    input[type="time"],
    input[type="text"],
    select {
      font-size: 12px;
      padding: 6px;
    }
  
    label {
      font-size: 12px;
    }
  
    button[type="submit"] {
      font-size: 14px;
      padding: 8px;
    }
  }
  .error {
    color: red;
    margin-top: 10px;
  }
  /* Other styles */

.success-message {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #4caf50;
  color: white;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}
/* Other styles */

.custom-select-container {
  position: relative;
  width: 100%;
  margin-bottom: 70px;
}

.custom-select-search {
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  margin-bottom: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.custom-select-options {
  max-height: 72px;
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  position: absolute;
  width: 100%;
  z-index: 1000;
}

.select-header {
  background: #f5f5f5;
  padding: 10px;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
}

.select-option {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
}

.select-option:last-child {
  border-bottom: none;
}

.select-option:hover {
  background: #f5f5f5;
}

.success-message {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #4caf50;
  color: white;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}
