/* src/MapComponent.css */

.custom-bus-marker {
  width: 35px;
  height: 35px;
  background-image: url('../images/bus.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.custom-stop-marker {
  width: 25px;
  height: 25px;
  background-image: url('../images/stop.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.stop-popup-content {
  font-family: Arial, sans-serif;
  font-size: 12px;
  line-height: 1.5;
  max-height: 300px; /* Ajustează această valoare după nevoie */
  overflow-y: auto;
  overflow-x: hidden; /* Adaugă această linie pentru a dezactiva scroll-ul pe orizontală */
}

.stop-name {
  font-weight: bold;
  margin-bottom: 5px;
}

.stop-lines {
  color: grey;
  margin-bottom: 10px;
}

.stop-lines.stop_description .NumberStyle {
  color: #333;
  font-size: 14px;
}

.stop-lines.stop_description .line {
  border-bottom: 1px solid #8be233;
  cursor: pointer;
  display: inline-block;
  margin: 2px 4px;
  padding: 5px;
}

.bus-arrivals {
  font-size: 12px;
  margin-top: 10px;
}

.vehicles-list {
  display: flex;
  flex-direction: column;
}

.box {
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 #dedede;
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px; /* Reduce margin-bottom */
  margin-top: 6px; /* Reduce margin-top */
  padding: 6px; /* Reduce padding */
  width: calc(100% - 20px); /* Ajustează lățimea */
}

.vehicle-id {
  background: #8be233;
  border-radius: 15px;
  color: #fff;
  font-size: 14px;
  margin-right: 10px;
  min-width: 30px;
  padding: 3px 12px;
  text-align: center;
}

.info {
  display: flex;
  flex-direction: column;
}

.info .text {
  color: grey;
  font-size: 10px;
}

.info .value {
  font-size: 12px;
}

/* Stiluri pentru popup-ul autobuzului */
.bus-popup-content {
  font-family: Arial, sans-serif;
  font-size: 12px;
  line-height: 1.5;
  min-width: 200px;
}

.bus-line {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.bus-line span {
  background: #8be233;
  border-radius: 15px;
  color: #fff;
  font-size: 14px;
  padding: 3px 12px;
  text-align: center;
}

.bus-update {
  color: grey;
  font-size: 10px;
  margin-bottom: 10px;
}

.bus-monitoring {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
}

.bus-info-box {
  display: flex;
  justify-content: space-between;
}

.bus-info-item {
  display: flex;
  flex-direction: column;
}

.bus-info-item .text {
  color: grey;
  font-size: 10px;
}

.bus-info-item .value {
  font-size: 12px;
}
.leaflet-popup-content-wrapper{
  min-width: 280px;
}
/* Responsive styles */
@media (max-width: 1200px) {
  /* Tablet */
  .stop-popup-content,
  .bus-popup-content {
    font-size: 14px;
  }
  
  .stop-lines.stop_description .NumberStyle {
    font-size: 16px;
  }
  
  .stop-lines.stop_description .line {
    font-size: 14px;
  }

  .box {
    flex-direction: column;
    align-items: flex-start;
  }

  .vehicle-id {
    font-size: 16px;
  }

  .info .text,
  .info .value {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  /* Mobile */
  .stop-popup-content,
  .bus-popup-content {
    font-size: 16px;
  }
  
  .stop-lines.stop_description .NumberStyle {
    font-size: 18px;
  }
  
  .stop-lines.stop_description .line {
    font-size: 16px;
  }

  .box {
    flex-direction: column;
    align-items: flex-start;
  }

  .vehicle-id {
    font-size: 18px;
  }

  .info .text,
  .info .value {
    font-size: 16px;
  }
}
