html, body, #root, .App {
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
}
  
.leaflet-container {
    flex-grow: 1; /* Asigură că harta ocupă tot spațiul rămas */
    width: 100%;
}

.search-popup {
    position: absolute;
    top: 1%;
    left: 10%;
    background-color: rgb(255, 255, 255);
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px;
    z-index: 1000;
    width: 300px;
    border-radius: 8px;
}
.search-menu {
    position: absolute;
    top: 1%;
    left: 1%;
    background-color: rgb(255, 255, 255);
    padding: 8px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px;
    z-index: 1001;
    border-radius: 8px;
}
.search-component {
    display: flex;
    flex-direction: column;
}
  
.search-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}
  
.search-header input {
    width: calc(100% - 50px);
    padding: 10px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #ddd;
}
  
.close-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
}
  
.search-results {
    max-height: 400px;
    overflow-y: auto;
}
  
.search-results h3 {
    background: #f5f5f5;
    padding: 10px;
    margin: 0;
    border-bottom: 1px solid #ddd;
}
  
.search-result-item {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
}
  
.search-result-item:last-child {
    border-bottom: none;
}
  
.search-result-item:hover {
    background: #f5f5f5;
}
  
.line-item {
    display: flex;
    align-items: center;
}
  
.line-number {
    margin-right: 10px;
    background: #00c853;
    border-radius: 50%;
    color: #fff;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
  
/* Responsive styles */
@media (max-width: 768px) {
    .search-popup {
      top: 10%;
      left: 5%;
      width: 90%;
      padding: 15px;
    }
    .search-menu {
      position: absolute;
      top: 1%;
      left: 1%;
      background-color: rgb(255, 255, 255);
      padding: 8px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px;
      z-index: 1001;
      border-radius: 8px;
    }
    .search-header input {
      width: calc(100% - 45px);
    }
  
    .search-results {
      max-height: 300px;
    }
}
  
@media (max-width: 480px) {
    .search-popup {
      top: 1%;
      left: 33%;
      width: 60%;
      padding: 10px;
    }
    .search-menu {
      position: absolute;
      top: 1%;
      left: 1%;
      background-color: rgb(255, 255, 255);
      padding: 8px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px;
      z-index: 1001;
      border-radius: 8px;
    }
    .search-header input {
      width: calc(100% - 60px);
    }
  
    .search-results {
      max-height: 200px;
    }
}
  
.schedule-button-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.schedule-button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.schedule-button:hover {
    background-color: #45a049;
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1001;
}

.popup-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    position: relative;
}

.popup-content .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
}
